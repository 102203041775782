.admin-layout {
    display: flex;
    height: 100%;
    overflow-y: auto;
}
@media (max-width: 6000px) {
    .admin-layout__sidebar {
        width: 236px;
        border-right: 2px solid var(--color-light-grey);
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
    }
    .admin-layout__main {
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-left: 236px;
    }
}

@media (max-width: 550px) {
    .admin-layout__sidebar {
        width: 100%;
        border-right: 2px solid var(--color-light-grey);
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
    }
    .admin-layout__main {
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-left: 100%;
    }
    .admin-layout__main .admin-layout__nav{
        padding-bottom: 12px;
    }
}

.setup-guide-menu {
    width: 276px;
}

.admin-layout__sidebar,
.admin-layout__main {
    transition: all 0.3s ease;
}

.admin-layout--hide-nav .admin-layout__sidebar {
    transform: translateX(-100%);
}

.admin-layout--hide-nav .admin-layout__main {
    margin-left: 0px;
}

.admin-layout__nav {
    width: 100%;
    border-bottom: 2px solid var(--color-light-grey);
    padding: 0 20px;
    padding-top: 12px;
}
.admin-layout__hamburger {
    color: currentColor !important;
    text-decoration: none;
}

.admin-layout__brand-logo {
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-bottom: 2px solid var(--color-light-grey);
}

.admin-layout__link-container {
    flex-grow: 1;
    overflow: hidden;
}

.admin-layout__sidebar,
.admin-layout__nav {
    background-color: white;
}

.admin-layout__brand-logo,
.admin-layout__nav {
    min-height: 68px;
}

.admin-layout__content {
    flex-grow: 1;
    overflow: hidden;
    background-color: #f8f9fa;
    position: relative;
}
