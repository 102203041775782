@import url("./fonts/stylesheet.css");
@font-face {
  font-family: 'Rubik';
  src: url('./fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  --color-primary: #6200ea;
  --color-secondary: #676b7e;
  --color-grey: #f5f5f5;
  --color-light-grey: #ecedf0;
  --color-blue-grey: #f8f9fa;
  --color-blue-grey-2: #ecedf0;
  --color-blue-grey-3: #dfe0e6;
  --color-blue-grey-6: #afb2c0;
  --color-blue-grey-7: #9b9fb1;
  --color-blue-grey-8: #83889e;
  --color-blue-grey-9: #676b7e;
  --color-blue-grey-10: #3c3e49;
  --color-purple-1: #f3ebff;
  --color-purple-2: #b388ff;
  --color-green-2: #b9f6ca;
  --color-green-5: #00d659;
  --bs-body-color: #3c3e49;
  --bs-body-bg: #f8f9fa !important;
  --color-dark-grey: #8b8f95;
  --color-dark-grey-2: #757575;
  --color-light-grey-2: #d9d9d9;
  --color-light-grey-3: #dfdfdf;
  --color-light-grey-4: #f5f5f5;
  --color-pink: #fc5185;
  --color-cyan: #3fc1cb;
  --button-primary: #324688;
  --button-text-primary: #ffffff;
  --bs-body-bg: #f8f9fa;
  --color-light-cyan: #61d0d8;
  --color-ButtonBackRegular :  #324688;
}

/* utils */

/* color */

.text-purple {
  color: var(--color-primary) !important;
}

.text-secondary {
  color: var(--color-primary) !important;
}

/* blue grey  */
.text-blue-grey-6 {
  color: var(--color-blue-grey-6) !important;
}
.text-blue-grey-7 {
  color: var(--color-blue-grey-7) !important;
}
.text-blue-grey-8 {
  color: var(--color-blue-grey-8) !important;
}
.text-blue-grey-10 {
  color: var(--color-blue-grey-10) !important;
}

/* font size  */

.f-10 {
  font-size: 0.625rem !important;
}

.f-12 {
  font-size: 0.75rem !important;
}

.f-14 {
  font-size: 0.875rem !important;
}

.f-18 {
  font-size: 1.125rem !important;
}

/* margin  */

.mb-64 {
  margin-bottom: 4rem !important;
}
.mt-64 {
  margin-top: 4rem !important;
}

.my-64 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.gx-64 {
  --bs-gutter-x: 4rem;
}
.gx-109 {
  --bs-gutter-x: 6.8125rem;
}

#demo-simple-select-filled.MuiSelect-select.MuiSelect-select {
  padding: 6px 36px 10px 12px !important;
}

.category-scroll::-webkit-scrollbar {
  width: 0;
}

.category-scroll {
  scrollbar-width: none;
}

.menu-bar::-webkit-scrollbar {
  width: 0 !important;
}

.menu-bar::-webkit-scrollbar-track {
  background: transparent !important;
}

.menu-bar::-webkit-scrollbar-thumb {
  background: transparent !important;
}

@media (min-width: 0px) {
  .container {
    max-width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .gx-md-64 {
    --bs-gutter-x: 4rem;
  }
  .gx-md-109 {
    --bs-gutter-x: 6.8125rem;
  }
  .gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }
}

@media (min-width: 992px) {
  .gx-lg-64 {
    --bs-gutter-x: 4rem;
  }
  .gx-lg-109 {
    --bs-gutter-x: 6.8125rem;
  }
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }
}

.pac-container  {
  border: 0;
  z-index: 9999;
}

.pac-item {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--website-builder-secondary-dark-accent-color);
  display: flex;
  flex-direction: column;
  background: url('../assets/location_icon.svg') no-repeat;
  background-size: 28px;
  border-radius: 4px;
  background-position: 12px 16px;
  padding-left: 48px;
  padding-right: 12px;
}
.pac-item:hover {
  background-color: #f6f0ff;
}
.pac-item-query {
color: var(--website-builder-secondary-dark-accent-color)
}
.pac-matched {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--website-builder-secondary-color) !important
}

.pac-icon {
  background: url('../assets/location_icon.svg') no-repeat center;
  background-size: 0px;
  border-radius: 4px;
  width: 0;
  height: 0;
}

.dialog-address-content {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 30px !important;
  /* scrollbar-color: var(--website-builder-primary-color) transparent; */
}
.dialog-address-content label.Mui-error{
  color: var(--website-builder-secondary-color) !important;
}

.dialog-address-content label.Mui-error span{
  color: var(--website-builder-secondary-color) !important;
}

.dialog-address-content input.pac-target-input:hover{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}
.dialog-address-content input.pac-target-input:focus-visible{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
  outline: none;
}

.dialog-address-content .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}
.dialog-address-content input:hover{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}
.dialog-address-content input:focus-visible{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}

.dialog-address-content fieldset:hover{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}

.dialog-address-content label.MuiFormLabel-root {
  font-family: var(--website-builder-body-font);
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--website-builder-secondary-color)  
}

.dialog-address-content span.MuiFormLabel-asterisk {
  font-family: var(--website-builder-body-font);
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--website-builder-secondary-color)  
}

.dialog-address-content::-webkit-scrollbar {
  width: 6px !important;
}

.dialog-address-content::-webkit-scrollbar-track {
  background: #fff !important;
}

.dialog-address-content::-webkit-scrollbar-thumb {
  background: var(--website-builder-primary-color) !important;
}

.dialog-saved-address-content {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 30px !important;
  scrollbar-color: transparent transparent;
}
.dialog-saved-address-content label.Mui-error{
  color: var(--website-builder-secondary-color) !important;
}

.dialog-saved-address-content label.Mui-error span{
  color: var(--website-builder-secondary-color) !important;
}

.dialog-saved-address-content input.pac-target-input:hover{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}
.dialog-saved-address-content input.pac-target-input:focus-visible{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
  outline: none;
}

.dialog-saved-address-content .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}
.dialog-saved-address-content input:hover{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}
.dialog-saved-address-content input:focus-visible{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}

.dialog-saved-address-content fieldset:hover{
  border-color: var(--website-builder-secondary-dark-accent-color) !important;
}

.dialog-saved-address-content label.MuiFormLabel-root {
  font-family: var(--website-builder-body-font);
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--website-builder-secondary-color)  
}

.dialog-saved-address-content span.MuiFormLabel-asterisk {
  font-family: var(--website-builder-body-font);
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--website-builder-secondary-color)  
}

.dialog-saved-address-content::-webkit-scrollbar {
  width: 0px !important;
}

.dialog-saved-address-content::-webkit-scrollbar-track {
  background: transparent !important;
}

.dialog-saved-address-content::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.gm-ui-hover-effect {
  position: absolute !important;
  right: 0;
  top: -2
}
@media (max-width: 6000px) {
  .edit-icon-delivery-address-cart {
    left: 92%;
    bottom: 65%
  }
  .order-notification-view-desktop {
    width: auto;
    padding: 4px;
    display: inline-flex
  }
  .order-notification-view-mobile {
    display: none;
  }
  .filter-landing-page-view {
    display: flex;
    align-items: center;
    background-color: #F1F5F9;
    flex-wrap: wrap;
    padding: 8px 15% 8px 15%;
  }
  .order-listing-search {
    margin: 40px 0;
  }
  .order-listing-title {
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    width: auto;
    margin-top: 40px;
  }
  .hide-web-element {
    display: none !important;
  }
  .image-logo {
    aspect-ratio: 184/40;
    width: 100%;
    max-width: 184px;
  }
  .category-view {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .link-text {
    font-size: 16px;
    margin-bottom: 12px;
    color: var(--website-builder-secondary-color);
    font-family: var(--website-builder-body-font);
    font-weight: 400;
    line-height: 24px;
  }
  .icon-footer {
    width: 36px;
    height: 36px
  }
  .food-label-image {
    height: 32px;
    width: 32px
  }
  .food-label-text {
    font-size: 14px;
    line-height: 22px;
  }
  .header-link {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    list-style: none;
    color: var(--website-builder-secondary-color);
    font-family: var(--website-builder-body-font);
    font-weight: 700
  }
  .header-link-contact {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    list-style: none;
    color: var(--website-builder-secondary-color);
    font-family: var(--website-builder-body-font);
    font-weight: 700
  }
  .item-link {
    margin-bottom: 5px;
  }
  div[data-virtuoso-scroller="true"] > div:first-child {
    top:-2px !important;
  }
  .price-text {
    font-size: 16px;
    line-height: 24px;
  }
  .landing-page-description-card {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    margin-top: 8px;
    line-height: 22px
  }
  .available-text {
    font-size: 16px;
    line-height: 24px;
    border-radius: 50px;
    padding: 8px 18px;
    color:var(--website-builder-secondary-color);
  }
  .new-price-text {
    font-size: 14px;
    line-height: 18px;
    margin-left: 8px;
  }
  .save-price-text {
    padding: 6px 10px;
    font-size: 12px;
    margin-left: 20px;
  }
  .title-card {
    font-size: 18px;
    line-height: 26px;
  }
  .landing-page-image-card {
    width: 180px;
    display: flex;
    justify-content: center;
  }
  .landing-page-image-card.skeleton {
    height:180px;
  }
  .landing-page-category {
    font-size: 18px;
    padding: 10px 20px;
  }
  .landing-page-category-arrow-right {
    margin-left: 20px;
  }
  .menu-bar {
    scrollbar-width: 0 !important;
    max-width: 30%;
  }
  .gap-category {
    height: 30px;
  }
  .landing-page-category-arrow-left {
    margin-right: 20px;
  }
  .landing-page-category-arrow-left .webIcon, .landing-page-category-arrow-right .webIcon{
    display: block;
  }
  .landing-page-category-arrow-left .mobIcon, .landing-page-category-arrow-right .mobIcon {
    display: none;
  }
  .landing-page-header-container {
    flex-direction: row;
    gap: 0px;
    padding-bottom: 18px;
  }
  .input-search-header {
    width: 25rem
  }
  .input-search-icon {
    display: none;
  }
  .landing-page-footer {
    flex-direction: row;
    height: 320px;
    padding-top: 60px;
    padding-left: 0px;
  }
  .landing-page-footer-contact {
    width: fit-content;
    padding: 0 5%;
  }
  .content-order-success {
    flex-direction: row;
    justify-content: 'space-between';
  }
  .content-place-order-success {
    font-size: 20px;
    line-height: 28px;
  }
  .content-order-success-close-icon {
    right: 145px
  }
  .content-order-success-divider {
    width: 2px;
    height: 100%; 
  }
  .content-order-success-text-view-right,.content-order-success-text-view-left {
    width: 49.5%;
    display: flex;
    align-items: center;
  }
  .content-order-success-text-view-left {
    justify-content: flex-end;
  }

  .content-order-success-text-right {
    max-width: 30ch;
    color: var(--Color-Black, #363636);
    font-family: var(--website-builder-body-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  .content-order-success-text-left {
    color: var(--Color-Black, #363636);
    font-family: var(--website-builder-body-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 150% */
  }
  .place-order-container {
    padding: 15px 20%;
  }
  .place-order-text {
    line-height: 24px;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .landing-page-mid-line-footer {
    display: none;
  }
  .text-footer {
    font-family: var(--website-builder-body-font);
    font-size: 16px;
    color: var(--website-builder-secondary-color);;
    font-weight: 400;
  }
  .images{
    height: 20px;
    width: 20px;
    color: #64748B;
  }
  .landing-page-line-footer {
    display: flex;
  }
  .landing-page-card {
    width: 70%;
    max-height: 195px;
    margin-bottom: 12px;
  }
  .card-divider {
    height: 12rem;
    width: 2px
  }
  .upper-view-product-detail {
    align-items: normal;
    flex-direction: row;
    width: 85%
  }
  .lower-view-product-detail {
    align-items: normal;
    flex-direction: row;
    width: 83%
  }
  .line-view-product-detail {
    width: 83%
  }
}

@media (max-width: 900px) {
  .place-order-container-main {
    width: 100% !important;
    margin: 0 !important;
  }
  .upper-view-product-detail {
    flex-direction: row;
    align-items: normal;
    width: 87%
  }
  .lower-view-product-detail {
    flex-direction: row;
    align-items: normal;
    width: 87%
  }
  .line-view-product-detail {
    width: 87%
  }
  .landing-page-category {
    font-size: 16px;
    padding: 10px 20px;
  }
  .landing-page-category-arrow-right {
    margin-left: 2px;
  }
  .landing-page-category-arrow-left {
    margin-right: 2px;
  }
  .hide-web-element {
    display: block !important;
  }
  .hide-mobbig-element {
    display: none !important;
  }
  .input-search-header {
    display: 22rem;
  }
  .landing-page-card {
    max-height: 150px;
    width: 85%;
    margin-bottom: 10px;
  }
  .card-divider {
    height: 10rem;
    width: 2px
  }
  .landing-page-image-card {
    width: 140px;
    display: flex;
    justify-content: center;
  }
  .landing-page-image-card.skeleton {
    height:140px
  }
}

@media (max-width: 750px) { 
  .place-order-container-main {
    width: 100% !important;
    margin: 0 !important;
  }
  .place-order-container {
    padding: 15px 15%;
  }
  .order-notification-view-desktop {
    display: none;
  }
  .order-notification-view-mobile {
    width: 100%;
    padding: 4px;
    display: inline-flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .title-card {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 550px) {
  .edit-icon-delivery-address-cart {
    left: 85%;
    bottom: 70%;
  }
  .filter-landing-page-view {
    display: flex;
    align-items: center;
    background-color: #F1F5F9;
    flex-wrap: wrap;
    padding: 8px 16px 8px 16px;
  }
  .order-listing-search {
    margin: 20px 0;
  }
  .order-listing-title {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .image-logo {
    aspect-ratio: 149/32;
    max-width: 149px;
    width: 100%;
  }
  .category-view {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .link-text {
    font-size: 12px;
    margin-bottom: 6px;
    color: var(--website-builder-secondary-color);
    font-family: var(--website-builder-body-font);
    font-weight: 400;
    line-height: 24px;
  }
  .icon-footer {
    width: 32px;
    height: 32px
  }
  .price-text {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
  }
  .landing-page-description-card {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }
  .available-text {
    font-size: 10px;
    line-height: 14px;
    border-radius: 20px;
    padding: 4px 10px;
  }
  .food-label-image {
    height: 26px;
    width: 26px
  }
  .food-label-text {
    font-size: 10px;
    line-height: 12px;
  }
  .item-link {
    margin-bottom: 6px;
  }
  .header-link {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    list-style: none;
    color: var(--website-builder-secondary-color);
    font-family: var(--website-builder-body-font);
    font-weight: 700
  }
  .header-link-contact {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    margin-bottom: 7px;
    list-style: none;
    color: var(--website-builder-secondary-color);
    font-family: var(--website-builder-body-font);
    font-weight: 700
  }
  .new-price-text {
    font-size: 12px;
    line-height: 18px;
    margin-left: 0px
  }
  .save-price-text {
    padding: 5px 6px;
    margin-left: 8px;
    font-size: 10px;
  }
  .upper-view-product-detail {
    align-items: center;
    flex-direction: column;
    width: 90%
  }
  .lower-view-product-detail {
    align-items: center;
    flex-direction: column;
    width: 90%;
    padding-bottom: 80px;
  }
  .line-view-product-detail {
    width: 90%
  }
  .content-order-success {
    flex-direction: column;
  }
  .content-order-success-text-right {
    color: var(--Color-Black, #363636);
    font-family: var(--website-builder-body-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    max-width: 100%;
    line-height: 18px; /* 150% */
  }
  .content-order-success-text-left {
    color: var(--Color-Black, #363636);
    font-family: var(--website-builder-body-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
  }
  .content-order-success-close-icon {
    right: 16px
  }
  .content-order-success-divider {
    width: 100%;
    height: 2px; 
  }
  .content-order-success-text-view-right {
    width: 75%;
    align-items: center;
    display: flex;
  }
  .content-order-success-text-view-left {
    width: 75%;
    display: flex;
    justify-content: flex-start;
  }
  .landing-page-header-container {
    flex-direction: column;
    gap: 8px;
    padding-bottom: 4px;
  }
  .landing-page-image-card {
    width: 80px;
    max-height: 110px;
    display: flex;
    justify-content: center;
  }
  .landing-page-image-card.skeleton {
    height:80px;
    margin-left:10px;
    border-radius: 12px;
  }
  .landing-page-category {
    font-size: 14px;
    padding: 6px 6px;
  }
  .input-search-icon {
    display: flex;
  }
  .input-search-header {
    display: none;
  }
}

@media (max-width: 450px) {
  .landing-page-category-arrow-right {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50px !important;
    margin-left: 6px;
  }
  .landing-page-category-arrow-left {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50px !important;
    margin-right: 6px;
  }
  .landing-page-category-arrow-left .webIcon, .landing-page-category-arrow-right .webIcon {
    display: none;
  }

  .landing-page-category-arrow-left .mobIcon, .landing-page-category-arrow-right .mobIcon {
    display: block;
  }

  .iconSize{
    width: 22px;
    height: 22px;
  }
  .landing-page-footer {
    flex-direction: column;
    gap: 10px;
    height: auto;
    padding-top: 0;
  }
  .landing-page-footer-contact {
    width: 100%;
    padding-left: 10%;
  }
  .content-place-order-success {
    font-size: 16px;
    line-height: 20px;
  }
  .place-order-text {
    line-height: 22px;
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .landing-page-mid-line-footer {
    display: flex;
  }
  .text-footer {
    font-family: var(--website-builder-body-font);
    font-size: 12px;
    color: var(--website-builder-secondary-color);
    font-weight: 400;
  }
  .images{
    height: 18px;
    width: 18px;
    color: #64748B;
  }
  .landing-page-line-footer {
    width:90%;
    margin-bottom: 10px;
    margin-top:28px;
    height:3px;
  }
  .landing-page-card {
    max-height: 150px;
    width: calc(100% - 32px);
    margin-bottom: 8px;
  }
  .card-divider {
    width: 0px;
    height: 8rem
  }
  .menu-bar {
    max-width: calc(100% - 68px);
  }
  .gap-category {
    height: 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1312px !important;
  }
  .yt-head-hide-srch-col {
    position: relative;
    left: 22.5% !important;
  }
}

@media (min-width: 400px) {
  .carousel-responsive-container .rec-carousel-wrapper {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
  }
}

@media (min-width: 0px) and (max-width: 400px) {
  .carousel-responsive-container {
    max-width: 100% !important;
    padding: 0px;
  }
}

/* Card  */

.card--reset {
  border-color: var(--color-blue-grey-3) !important;
  border-radius: 3px !important;
}

iframe {
  display: none;
}

.csvuploadImportBtn:disabled{
  background-color: #E2E8F0 !important;
  color: #64748B !important;
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
  margin-right: 14px
}

.csvuploadImportBtn:disabled{
  background-color: #E2E8F0 !important;
  color: #64748B !important;
}