.snackbar-message-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 95vw;
}

.snackbar-logo {
    height: 20;
    width: 160;
    margin: 4;
    transform: translateY(-2px);
}

.snackbar-message {
    width: 100%;
    display: flex;
    text-overflow: ellipsis;
    justify-content: center;
}

.snackbar-message-icon {
    width: 24;
    height: 24;
    margin: 0 8;
}

.snackbar-message-icon-message {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-right: 6px;
}

.snackbar-message-content {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}